import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';
import {Container} from '@mui/material';

export const ContProveedores = styled.div`
  width: auto;
  height: auto;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 30px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 50px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 40px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    padding-top: 50px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 60px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 70px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 80px;
  }
  @media(min-width: 1920px){
    padding-top: 100px;
  }
`
export const ContProveedoresInner = styled(Container)`
  background-color: ${GlobalColors.colorBtnTextPrimary};
  box-shadow: 0px 8px 28px -6px rgba(53, 199, 248, 0.16), 0px 18px 88px -4px rgba(53, 199, 248, 0.12);
  border-radius: 20px;
`
export const ContTitle = styled.div`
  font: 56px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 40px;
    margin-bottom: 25px;
    font-size: 36px;
    line-height: 46px;  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 40px;
    margin-bottom: 25px;
    font-size: 48px;
    line-height: 58px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 32px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 34px;
    line-height: 38px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 38px;
    line-height: 42px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 42px;
    line-height: 46px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 46px;
    line-height: 54px;
  }
  @media(min-width: 1920px){
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 56px;
    line-height: 70px;
  }
`
export const Title1 = styled.div`
  color: ${GlobalColors.colorPrimary};
`
export const Title2 = styled.span`
  color: ${GlobalColors.colorSecondary};
`
export const ContInfo = styled.div`
  width: 100%;
  
  @media(min-width: 300px) and (max-width: 1023px){
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const Desc = styled.div`
  font: 24px ${GlobalFonts.fontMedium};
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 26px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 70%;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 32px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 26px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 32px;
  }
  @media(min-width: 1920px){
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 35px;
  }
`
export const ContImgsProveedores = styled.div`
  width: auto;
  height: auto;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding: 20px 80px 35px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding: 20px 60px 60px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding: 20px 80px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    padding: 20px 80px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding: 25px 80px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding: 25px 80px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding: 30px 80px;
  }
  @media(min-width: 1920px){
    padding: 30px 100px;
  }
`
export const ContImgProvee = styled.div`
  width: 90%;
  height: auto;
  
  @media(min-width: 300px) and (max-width: 767px){
      
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin: 10px 0;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ImgProvee = styled.img`
  width: 100%;
  height: auto;
`
