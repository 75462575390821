import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';
import BackFooter from '../../assets/images/back-footer.svg';
import BackFooterTabblet from '../../assets/images/back-footer-tablet.svg';

export const ContSolicita = styled.div`
  position: relative;
  width: auto;
  height: auto;
  margin-top: -5px;

  background-color: ${GlobalColors.colorPrimary};
  background-image: url(${BackFooter});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
  z-index: 1;

  @media(min-width: 300px) and (max-width: 767px){
    padding: 30px 25px 60px;
    background-image: url(${BackFooterTabblet});
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding: 50px 80px 60px;
    background-image: url(${BackFooterTabblet});
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding: 50px 200px 60px;
    background-image: url(${BackFooterTabblet});
    background-position: center 200px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    padding: 50px 200px 60px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding: 50px 200px 60px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding: 50px 200px 60px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding: 50px 200px 60px;
  }
  @media(min-width: 1920px){
    padding: 50px 250px 70px;
  }
`
export const ContSolicitaInner = styled.div`
  width: 100%;
  height: auto;
  background-color: ${GlobalColors.colorBtnTextPrimary};
  box-shadow: 0px 8px 28px -6px rgba(53, 199, 248, 0.16), 0px 18px 88px -4px rgba(53, 199, 248, 0.12);
  border-radius: 20px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const ContTitle = styled.div`
  font: 56px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 40px;
    margin-bottom: 25px;
    font-size: 26px;
    line-height: 30px;  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 40px;
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 34px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 32px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    padding-top: 25px;
    margin-bottom: 35px;
    font-size: 34px;
    line-height: 38px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 25px;
    margin-bottom: 35px;
    font-size: 38px;
    line-height: 42px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 25px;
    margin-bottom: 35px;
    font-size: 42px;
    line-height: 46px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 40px;
    margin-bottom: 40px;
    font-size: 46px;
    line-height: 54px;
  }
  @media(min-width: 1920px){
    padding-top: 50px;
    margin-bottom: 50px;
    font-size: 56px;
    line-height: 70px;
  }
`
export const Title1 = styled.div`
  color: ${GlobalColors.colorPrimary};
`
export const Title2 = styled.div`
  color: ${GlobalColors.colorSecondary};
`
export const ContInfo = styled.div`
  width: 100%;
  margin-bottom: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 1023px){
    text-align: center;
  }
`
export const Desc = styled.div`
  font: 24px ${GlobalFonts.fontMedium};
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 26px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80%;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 32px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 80%;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 80%;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 26px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 32px;
  }
  @media(min-width: 1920px){
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 35px;
  }
`
export const DescBold = styled.span`
  font-family: ${GlobalFonts.fontBold};
  font-weight: 700;
`
export const ContButton = styled.div`
  padding-bottom: 50px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
      
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 30%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 30%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 100%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 100%;
  }
  @media(min-width: 1920px){
    width: 100%;
  }
`
export const Circle = styled.div`
  position: relative;
  background-color: #FFFFFF;
  border-radius: 50%;
  z-index: 0;
  
  @media(min-width: 300px) and (max-width: 767px){
      
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    width: 3276px;
    height: 1532px;
  }
`

