import styled, {css} from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContButton = styled.div`
  width: 215px;
  height: 70px;
  background: linear-gradient(73.69deg, #5433FF -18.91%, #20BDFF 65.98%, #A5FECB 124.27%);
  border-radius: 10px;
  float: right;
  
  &:hover{
    background: linear-gradient(298.69deg, #5433FF -18.91%, #20BDFF 65.98%, #A5FECB 124.27%);
  }

  &:active{
    background: ${GlobalColors.colorSecondary};
  }

  @media(min-width: 300px) and (max-width: 1023px){
    display: ${props => props.location === 'normal' ? 'block' : 'none'};
  }
  
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 200px;
    height: 50px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 200px;
    height: 50px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 100%;
    height: 55px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 200px;
    height: 60px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 200px;
    height: 60px;
  }
  @media(min-width: 1920px){
    width: 215px;
  }
`
export const CfgBtnLink = css`
  width: 100%;
  height: 100%;
  
  background-color: transparent;
  border: none;

  /* Display & Box Model */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
  
  /* Typografhy */
  text-decoration: none;
  font: 15px ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorBtnTextPrimary};
  cursor: pointer;
  
  &:hover{
    //color: ${GlobalColors.colorSecondary};
  }
  
  &[aria-current]{
    color: ${props => props.color};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding: 10px;
    font-size: 13px;
    margin-right: 15px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding: 10px;
    font-size: 15px;
    margin-right: 15px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    padding: 15px;
    font-size: 15px;
    margin-right: 15px;
  }
  @media(min-width: 1920px){
    padding: 15px;
    font-size: 15px;
    margin-right: 15px;
  }
`
export const Btn = styled.button`
  ${CfgBtnLink}
`
export const BtnAnchor = styled.a`
  padding: 0 !important;
  ${CfgBtnLink}
`
