import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import Index from './views';
import Accepted from './views/Accepted';
import Pending from './views/Pending';
import Rejected from './views/Rejected';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={ <Index />} />
        <Route path='/accepted' element={ <Accepted />}/>
        <Route path='/pending' element={ <Pending />} />
        <Route path='/rejected' element={ <Rejected />} />
      </Routes>
    </Router>
  );
}

export default App;
