import React, {useState} from 'react';
import {
  BodyMenuMobile,
  ContBodyMenuMobile,
  ContFooterMenuMobile, ContItemsMenuMobile,
  ContMenuMobile, ImgMenu,
  ItemMenuMobile,
} from './MenuMobile.styles';
import icoMenu from '../../assets/images/ico-hamburger.svg';
import icoClose from '../../assets/images/ico-close.svg';

const MenuMobile = () => {
  const [onShowMenu, setOnShowMenu] = useState(false);

  const onShowMenuMobile = () => {
    console.log('onShowMenuMobile =>', !onShowMenu);
    setOnShowMenu(!onShowMenu);
  };

  const fnGoToElement = (el) => {
    setOnShowMenu(false);

    setTimeout(function () {
      const yOffset = -100;
      const titleElement = document.getElementById(el);
      // titleElement.scrollIntoView({ behavior: 'smooth' });
      const y = titleElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }, 500);
  };

  return (
    <ContMenuMobile>
      <ImgMenu src={onShowMenu ? icoClose : icoMenu} alt="" onClick={onShowMenuMobile}/>
      {onShowMenu
        ? (
          <ContBodyMenuMobile>
            <BodyMenuMobile>
              <ContItemsMenuMobile>
                <ItemMenuMobile onClick={() => fnGoToElement('beneficios')}>Beneficios</ItemMenuMobile>
                <ItemMenuMobile onClick={() => fnGoToElement('como')}>¿Cómo obtenerlo?</ItemMenuMobile>
                <ItemMenuMobile onClick={() => fnGoToElement('proveedores')}>Nuestros proveedores</ItemMenuMobile>
                <ItemMenuMobile onClick={() => fnGoToElement('casos')}>Casos de éxito</ItemMenuMobile>
              </ContItemsMenuMobile>
              <ContFooterMenuMobile>
                <div>2022 Enpagos©</div>
                <div>Todos los derechos reservados</div>
              </ContFooterMenuMobile>
            </BodyMenuMobile>
          </ContBodyMenuMobile>
        ) : null

      }
    </ContMenuMobile>
  );
};

export default MenuMobile;
