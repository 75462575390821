import styled from 'styled-components';

export const ContMenu = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;

  @media(min-width: 300px) and (max-width: 1023px){
    display: none;
  }  
`
