import styled, {createGlobalStyle} from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const StylesCasos = createGlobalStyle`
  #casos .alice-carousel{
    position: relative;
    //width: 100%;
    margin: auto;
    direction: ltr;
    
    @media(min-width: 300px) and (max-width: 767px){
      width: 100% !important;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      width: 100% !important;
    }
    @media(min-width: 1024px) and (max-width: 1079px){
      width: 70% !important;
    }
    @media(min-width: 1080px) and (max-width: 1219px){
      width: 70% !important;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      width: 70% !important;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      width: 80% !important;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      width: 80% !important;
    }
    @media(min-width: 1920px){
      width: 86% !important;
    }
  }
  #casos .alice-carousel__wrapper {
    @media(min-width: 300px) and (max-width: 767px){
      height: 360px !important;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      height: 360px !important;
    }
    @media(min-width: 1024px) and (max-width: 1079px){
      height: 360px !important;
    }
    @media(min-width: 1080px) and (max-width: 1219px){
      height: 360px !important;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      height: 360px !important;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      height: 390px !important;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      height: 390px !important;
    }
    @media(min-width: 1920px){
      height: 370px !important;
    }
  }
  #casos .alice-carousel__stage-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  #casos .alice-carousel__dots {
    float: none;
  }
`

export const ContCasos = styled.div`
  width: auto;
  height: auto;
  
  background-color: ${GlobalColors.colorPrimary};

  @media(min-width: 300px) and (max-width: 767px){
    margin-top: 30px;
    padding: 30px 0;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-top: 50px;
    padding: 50px 0;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-top: 40px;
    padding: 40px 0;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    margin-top: 50px;
    padding: 50px 0;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-top: 60px;
    padding: 60px 0;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-top: 70px;
    padding: 70px 0;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-top: 80px;
    padding: 80px 0;
  }
  @media(min-width: 1920px){
    margin-top: 100px;
    padding: 100px 0;
  }
`
export const ContCasosInner = styled.div`
  width: auto;
  height: auto;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin: 0 !important;
    padding: 0 0 30px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin: 0 !important;
    padding: 0 0 40px !important;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin: 0 !important;
    padding: 0 0 40px !important;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    margin: 0 !important;
    padding: 0 0 40px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin: 0 100px !important;
    padding: 0 0 50px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin: 0 150px !important;
    padding: 0 0 50px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin: 0 150px !important;
    padding: 0 0 50px !important;
  }
  @media(min-width: 1920px){
    margin: 0 200px !important;
    padding: 0 0 50px !important;
  }
`
export const ContTitle = styled.div`
  font: 56px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 40px;
    margin: 0 20px 25px;
    font-size: 36px;
    line-height: 46px;  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 40px;
    margin-bottom: 25px;
    font-size: 48px;
    line-height: 58px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 32px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 34px;
    line-height: 38px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 38px;
    line-height: 42px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 42px;
    line-height: 46px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 46px;
    line-height: 54px;
  }
  @media(min-width: 1920px){
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 56px;
    line-height: 70px;
  }
`
export const Title1 = styled.div`
  color: ${GlobalColors.colorBtnTextPrimary};
`
export const Title2 = styled.span`
  color: ${GlobalColors.colorSecondary};
`
export const ContInfo = styled.div`
  width: 100%;
  
  @media(min-width: 300px) and (max-width: 1023px){
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const Desc = styled.div`
  font: 24px ${GlobalFonts.fontMedium};
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  text-align: center;
  color: ${GlobalColors.colorBtnTextPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 26px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 70%;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 32px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 24px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 26px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 28px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 32px;
  }
  @media(min-width: 1920px){
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 35px;
  }
`
export const DescBold = styled.span`
  font-family: ${GlobalFonts.fontBold};
  font-weight: 700;
`
export const ContSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`
export const ContSliderInner = styled.div`
  width: auto;
`
export const BtnCarousel = styled.button`
  width: 70px;
  height: 70px;
  background: linear-gradient(65.38deg, #5433FF -161.14%, #20BDFF -13.51%, #A5FECB 125.08%);
  border: none !important;
  border-radius: 50%;
  cursor: pointer;
  
  @media(min-width: 300px) and (max-width: 767px){
      
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    margin: 0 40px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ItemCarousel = styled.div`
  width: calc(100% - 20px) !important;
  height: 100% !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ItemCarouselInner = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 15px;
  background-color: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const ContItemPicture = styled.div`
  position: relative;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ContIcoComment = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 5px;
  right: 5px;
`
export const ItemPicture = styled.div`
  width: 120px;
  height: 120px;
  background-image: url('${props => props.picture}');
  background-size: contain;
  background-repeat: no-repeat;
  
  @media(min-width: 300px) and (max-width: 767px){
      
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 110px;
    height: 110px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ItemName = styled.div`
  margin-bottom: 15px;
  font: 20px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
      
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    font-size: 18px;
    margin-bottom: 13px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 13px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ItemBusiness = styled.div`
  margin-bottom: 15px;
  font: 15px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: ${GlobalColors.colorPrimaryDark};
  
  @media(min-width: 300px) and (max-width: 767px){
      
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    font-size: 13px;
    margin-bottom: 13px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    margin-bottom: 13px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ItemComment = styled.div`
  margin-bottom: 15px;
  font: 15px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: #828282;
  
  @media(min-width: 300px) and (max-width: 767px){
      
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    font-size: 12px;
    margin-bottom: 13px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font-size: 12px;
    line-height: 20px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ItemDot = styled.div`
  position: relative;
  margin-bottom: 15px;
  width: 13px;
  height: 13px;
  background: linear-gradient(65.38deg, #5433FF -161.14%, #20BDFF -13.51%, #A5FECB 125.08%);
  border-radius: 50%;
`
