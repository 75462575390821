import React from 'react';
import {BtnLink, ContItemMenu} from './ItemMenu.styles';
import {GlobalColors} from '../../globals';

const ItemMenu = ({text, linkTo}) => {
  const fnGoToElement = (el) => {
    setTimeout(function(){
      const yOffset = -100;
      const titleElement = document.getElementById(el);
      // titleElement.scrollIntoView({ behavior: 'smooth' });
      const y = titleElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({top: y, behavior: 'smooth'});
    }, 500);
  }

  return (
    <ContItemMenu>
      <BtnLink
        color={GlobalColors.colorPrimary}
        onClick={() => fnGoToElement(linkTo)}
      >
        {text}
      </BtnLink>
    </ContItemMenu>
  );
};

export default ItemMenu;
