import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';
import {Container} from '@mui/material';

export const ImgMenu = styled.img`
  margin-right: -15px;
  float: right;
`
export const ContMenuMobile = styled(Container)`
  width: 64px;
  height: 64px;
  padding: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(min-width: 1024px){
    display: none !important;
  }
`
export const ContBodyMenuMobile = styled(Container)`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 65px;
  left: 0;
  background-color: ${GlobalColors.colorPrimary};
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`
export const BodyMenuMobile = styled.div`
  width: 100%;
  height: calc(100% - 190px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`
export const ContItemsMenuMobile = styled.div`
  width: 100%;
`
export const ItemMenuMobile = styled.button`
  width: 100%;
  height: 110px;
  padding: 15px 0;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${GlobalColors.colorBorder};
  font: 24px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: ${GlobalColors.colorBtnTextPrimary};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ContFooterMenuMobile = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 200px;
  padding: 0 !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font: 15px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: #FBFFFF;

`
