import styled from 'styled-components';
import {Grid} from '@mui/material';
import {GlobalColors} from '../../globals';

export const ContHeaderFirst = styled.div`
  position: fixed;
  width: 100%;
  background-color: #FFFFFF;
  border-bottom: 1px solid ${GlobalColors.colorBorder};
  z-index: 9;
`
export const ContHeader = styled.div`
  width: auto;
  height: 110px;
  //padding: 0 25px;
  background-color: transparent;
  // border-bottom: 1px solid ${GlobalColors.colorBorder};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  @media(min-width: 300px) and (max-width: 1079px){
    height: 64px;
    margin: 0 20px !important;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    height: 80px;
    margin: 0 50px !important;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: 80px;
    margin: 0 100px !important;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: 80px;
    margin: 0 100px !important;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    height: 80px;
    margin: 0 100px !important;
  }
  @media(min-width: 1920px) and (max-width: 3200px){
    margin: 0 200px !important;
  }
`
export const ContHeaderInner = styled(Grid)`
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-direction: row !important;
`
