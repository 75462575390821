import React from 'react';
import {ContFooter, ContFooterInner, TextFooter, TextFooterAddress, TextFooterReg} from './Footer.styles';

const Footer = () => {

  return (
    <ContFooter>
      <ContFooterInner>
        <TextFooterAddress>Garza Sada 2116, Col. Roma, Monterrey, Nuevo Leon, México, 64700</TextFooterAddress>
        <TextFooter>
          QUALITY FINANCIAL SERVICES, S.A.P.I. DE C.V. SOFOM., E.N.R., Entidad No Regulada ("Enpagos"), para su constitución y operación con tal carácter, no requiere de autorización de la Secretaría de Hacienda y Crédito Público, no obstante, se encuentra sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores, únicamente para efectos de lo dispuesto por el artículo 56 de la Ley General de Organizaciones y Actividades Auxiliares del Crédito. Enpagos©. Todos los derechos reservados. Prohibida la reproducción total o parcial del contenido de este sitio.
        </TextFooter>
        <TextFooterReg>2022 Enpagos© | Todos los derechos reservados</TextFooterReg>
      </ContFooterInner>
    </ContFooter>
  );
};

export default Footer;
