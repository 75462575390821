import React from 'react';
import Layout from '../components/Layout/';
import Home from '../components/Home';
import Beneficios from '../components/Beneficios';
import NuestrosProveedores from '../components/NuestrosProveedores';
import CasosExito from '../components/CasosExito';
import ComoObtenerlo from '../components/ComoObtenerlo';
// import Financiamiento from '../components/Financiamiento';
import SolicitaCredito from '../components/SolicitaCredito';
import Footer from '../components/Footer';

const Index = () => {

  return (
    <Layout>
      <Home/>
      <Beneficios/>
      <ComoObtenerlo/>
      <NuestrosProveedores/>
      {/*<Financiamiento/>*/}
      <CasosExito/>
      <SolicitaCredito/>
      <Footer/>
    </Layout>
  )
}

export default Index;
