import React from 'react';
import {
  ContBack,
  ContBeneficios,
  ContBeneficiosInner, ContCircle1, ContCircle2, ContDegraded1, ContDegraded2,
  ContSquareA1, ContSquareA2,
  ContSquareB1, ContSquareB2,
  ContTitle,
  Square,
  TextSquareBold,
  Title1,
  Title2,
} from './Beneficios.styles';
import {Grid} from '@mui/material';
import {LogoBlanco} from '../Logo';

const Beneficios = () => {
  return (
    <ContBeneficios id='beneficios' className={'container-fit'}>
      <ContBeneficiosInner maxWidth={false} className={'no-padding-desk'}>
        <ContTitle>
          <Title1>¿Por qué solicitar tu</Title1>
          <Title2>crédito con Enpagos?</Title2>
        </ContTitle>
        <Grid container spacing={2} className={'grid-fit center-in-column'}>
          <Grid item md={4.5} className={'no-padding-left no-padding-top-mobile'}>
            <ContSquareA1>
              <Square>
                Pre-autorizamos tu <TextSquareBold>crédito de manera inmediata.</TextSquareBold>
              </Square>
            </ContSquareA1>
            <ContSquareB1>
              <Square>
                <TextSquareBold>Crédito del 100%</TextSquareBold> del equipo que necesites.
              </Square>
            </ContSquareB1>
          </Grid>
          <Grid item md={3} className={'no-padding-left no-show-mobile'}>
            <LogoBlanco />
          </Grid>
          <Grid item md={4.5} className={'no-padding-left no-padding-top-mobile'}>
            <ContSquareA2>
              <Square>
                Paga en parcialidades <TextSquareBold>de hasta 60 meses,</TextSquareBold> en OXXO o por transferencia.
              </Square>
            </ContSquareA2>
            <ContSquareB2>
              <Square>
                Interés fijo, menor al <TextSquareBold>de una tarjeta de crédito.</TextSquareBold>
              </Square>
            </ContSquareB2>
          </Grid>
        </Grid>
      </ContBeneficiosInner>
      <ContBack/>
      <ContDegraded1/>
      <ContDegraded2/>
      <ContCircle1/>
      <ContCircle2/>
    </ContBeneficios>
  );
};

export default Beneficios;
