import styled from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const ContFooter = styled.div`
  position: relative;
  width: auto;
  height: auto;
  margin-top: -2px;
  background-color: #FBFFFF;
  z-index: 2;

  text-align: center;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 30px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 40px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 40px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    padding-top: 40px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 40px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 45px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 45px;
  }
  @media(min-width: 1920px){
    padding-top: 45px;
  }
`
export const ContFooterInner = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    width: 90%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 90%;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 70%;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 70%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 70%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 70%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 70%;
  }
  @media(min-width: 1920px){
    width: 70%;
  }
`
export const TextFooterAddress = styled.div`
  margin-bottom: 45px;
  font: 15px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: #828282;
`
export const TextFooter = styled.div`
  margin-bottom: 80px;
  font: 15px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: #828282;
`
export const TextFooterReg = styled.div`
  margin-bottom: 30px;
  font: 15px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  color: ${GlobalColors.colorPrimary};
`
