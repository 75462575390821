import styled, {css} from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';
import {Container} from '@mui/material';
import imgBack from  '../../assets/images/back-beneficios.svg';
import imgBackMobile from  '../../assets/images/back-beneficios-mobile.svg';
import imgBackTablet from  '../../assets/images/back-beneficios-tablet.svg';
import imgCircle1 from  '../../assets/images/circle-1.svg';
import imgCircle2 from  '../../assets/images/circle-2.svg';
import imgDegraded1 from  '../../assets/images/degraded-1.svg';
import imgDegraded2 from  '../../assets/images/degraded-2.svg';

export const ContBeneficios = styled.div`
  position: relative;
  width: auto;
  height: auto;
`
export const ContBeneficiosInner = styled(Container)`
  position: relative;
  padding-bottom: 70px !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 1;
  
  @media(min-width: 300px) and (max-width: 767px){
    height: auto;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    height: auto;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    height: 501px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    height: 501px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    height: 501px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    height: 508px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    height: 520px;
  }
  @media(min-width: 1920px) and (max-width: 3200px){
    height: 726px;
  }
`
export const cfgBacks = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  z-index: 0;
`
export const ContBack = styled.div`
  background: ${GlobalColors.colorPrimary};
  border-radius: 20px;
  ${cfgBacks}
`
export const ContCircle1 = styled.div`
  background-image: url(${imgCircle1});
  background-position: top left;
  @media(min-width: 300px) and (max-width: 767px){
    background-size: 15%;
  }
  ${cfgBacks}
`
export const ContCircle2 = styled.div`
  background-image: url(${imgCircle2});
  background-position: bottom right;
  @media(min-width: 300px) and (max-width: 767px){
    background-size: 15%;
  }
  ${cfgBacks}
`
export const ContDegraded1 = styled.div`
  background-image: url(${imgDegraded1});
  background-position: bottom left;
  ${cfgBacks}
`
export const ContDegraded2 = styled.div`
  background-image: url(${imgDegraded2});
  background-position: top right;
  ${cfgBacks}
`
export const ContTitle = styled.div`
  font: 56px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 70px;
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    padding-top: 40px;
    margin-bottom: 25px;
    font-size: 26px;
    line-height: 30px;  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding-top: 40px;
    margin-bottom: 25px;
    font-size: 28px;
    line-height: 34px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 32px;
    line-height: 36px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 34px;
    line-height: 38px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 38px;
    line-height: 42px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding-top: 25px;
    margin-bottom: 25px;
    font-size: 42px;
    line-height: 46px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 46px;
    line-height: 54px;
  }
  @media(min-width: 1920px){
    padding-top: 30px;
    margin-bottom: 30px;
    font-size: 56px;
    line-height: 70px;
  }
`
export const Title1 = styled.div`
  color: ${GlobalColors.colorBtnTextPrimary};
`
export const Title2 = styled.div`
  color: ${GlobalColors.colorSecondary};
`
export const ContSquareA1 = styled.div`
  position: relative;
  margin-top: 15px;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: auto;
    left: 0;
    padding: 0 15px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 220px;
    left: 15%;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 240px;
    left: 15%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 260px;
    left: 15%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 280px;
    left: 15%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 300px;
    left: 15%;
  }
  @media(min-width: 1920px){
    width: 300px;
    left: 15%;
  }
`
export const ContSquareB1 = styled.div`
  position: relative;
  margin-top: 15px;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: auto;
    left: 0;
    padding: 0 15px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 220px;
    left: 30%;
    margin-top: 32px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 240px;
    left: 30%;
    margin-top: 32px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 260px;
    left: 30%;
    margin-top: 32px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 280px;
    left: 30%;
    margin-top: 32px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 300px;
    left: 30%;
    margin-top: 32px;
  }
  @media(min-width: 1920px){
    width: 300px;
    left: 30%;
    margin-top: 72px;
  }
`
export const ContSquareA2 = styled.div`
  position: relative;
  margin-top: 15px;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: auto;
    left: 0;
    padding: 0 15px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 220px;
    left: 20%;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 240px;
    left: 20%;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 260px;
    left: 20%;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 280px;
    left: 20%;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 300px;
    left: 20%;
  }
  @media(min-width: 1920px){
    width: 300px;
    left: 25%;
  }
`
export const ContSquareB2 = styled.div`
  position: relative;
  margin-top: 15px;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: auto;
    left: 0;
    padding: 0 15px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 220px;
    left: 4%;
    margin-top: 32px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 240px;
    left: 4%;
    margin-top: 32px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 260px;
    left: 4%;
    margin-top: 32px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 280px;
    left: 4%;
    margin-top: 32px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 300px;
    left: 4%;
    margin-top: 32px;
  }
  @media(min-width: 1920px){
    width: 300px;
    left: 7%;
    margin-top: 72px;
  }
`
export const Square = styled.div`
  width: auto;
  height: auto;
  padding: 38px 7px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid ${GlobalColors.colorSecondary}; 

  font: 24px ${GlobalFonts.fontMedium};
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  color: ${GlobalColors.colorBtnTextPrimary};
  text-align: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: auto;
    padding: 24px 11px;
    font: 16px ${GlobalFonts.fontMedium};
    line-height: 22px;  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
    padding: 28px 11px;
    font: 16px ${GlobalFonts.fontMedium};
    line-height: 22px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    width: 220px;
    padding: 24px 12px;
    font: 14px ${GlobalFonts.fontMedium};
    line-height: 20px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    width: 240px;
    padding: 24px 12px;
    font: 14px ${GlobalFonts.fontMedium};
    line-height: 20px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    width: 260px;
    padding: 28px 15px;
    font: 16px ${GlobalFonts.fontMedium};
    line-height: 24px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    width: 280px;
    padding: 28px 15px;
    font: 18px ${GlobalFonts.fontMedium};
    line-height: 26px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    width: 300px;
    padding: 28px 15px;
    font: 20px ${GlobalFonts.fontMedium};
    line-height: 28px;
  }
  @media(min-width: 1920px){
    width: 356px;
    padding: 32px 15px;
    font: 24px ${GlobalFonts.fontMedium};
    line-height: 35px;
  }
`
export const TextSquareBold = styled.span`
  font-family: ${GlobalFonts.fontBold};
`
