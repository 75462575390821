import React from 'react';
import {ContMenu} from './Menu.styles';
import ItemMenu from '../ItemMenu';

const Menu = () => {

  return (
    <ContMenu>
      <ItemMenu text='Beneficios' linkTo='beneficios'/>
      <ItemMenu text='¿Cómo obtenerlo?' linkTo='como'/>
      <ItemMenu text='Nuestros proveedores' linkTo='proveedores'/>
      <ItemMenu text='Casos de éxito' linkTo='casos'/>
    </ContMenu>
  );
};

export default Menu;
