import React, {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import {
  ContComoObtenerlo,
  ContTitle,
  Title1,
  Title2,
  ItemCarousel,
  StylesComoObtenerlo,
  BtnCarousel, TitleStep, DescStep, MottoStep, ContInfo,
} from './ComoObtenerlo.styles';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ImgPaso1 from '../../assets/images/img-paso-1.svg';
import ImgPaso2 from '../../assets/images/img-paso-2.svg';
import ImgPaso3 from '../../assets/images/img-paso-3.svg';
import ImgPaso4 from '../../assets/images/img-paso-4.svg';
import ImgArrowPrev from '../../assets/images/ico-arrow-prev.svg';
import ImgArrowNext from '../../assets/images/ico-arrow-next.svg';

const MaxLimit = 4;

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  1024: { items: 1 },
};

const dataInfo = [
  {
    step: 1,
    title: 'Busca el equipo o maquinaria que necesitas',
    desc: 'Explora entre nuestras más de 20 tiendas y escoge el equipo que quieres comprar.',
    img: ImgPaso1,
  },
  {
    step: 2,
    title: 'Cotiza y selecciona tu plazo',
    desc: 'Personaliza el plazo de acuerdo a tus necesidades.',
    img: ImgPaso2,
  },
  {
    step: 3,
    title: 'Análisis crediticio',
    desc: 'Esto tardará algunos minutos ya que nos encargamos de analizar tu perfil.',
    img: ImgPaso3,
  },
  {
    step: 4,
    title: 'Obtén tu autorización',
    desc: 'Se autoriza en máximo 48 horas.',
    img: ImgPaso4,
  },
];

const createItems = (length, [handleClick]) => {
  let deltaX = 0;
  let difference = 0;
  const swipeDelta = 20;

  return Array.from({ length }).map((item, i) => (
    <ItemCarousel
      data-value={i + 1}
      className="item"
      onMouseDown={(e) => (deltaX = e.pageX)}
      onMouseUp={(e) => (difference = Math.abs(e.pageX - deltaX))}
      onClick={() => (difference < swipeDelta) && handleClick(i)}
      bg={dataInfo[i].img}
    />
  ));
};

const ComoObtenerlo = () => {
  const [info, setInfo] = useState(dataInfo);
  const [activeIndex, setActiveIndex] = useState(0);
  const [items] = useState(createItems(MaxLimit, [setActiveIndex]));

  const slidePrev = () => {
    if (activeIndex > 0) {
      setActiveIndex(activeIndex - 1);
    }
  };
  const slideNext = () => {
    if (activeIndex < (MaxLimit - 1)) {
      setActiveIndex(activeIndex + 1);
    }
  };
  const syncActiveIndex = ({ item }) => {
    setActiveIndex(item);
  };

  /*useEffect(() => {
    console.log('activeIndex =>', activeIndex);
    console.log('info =>', info);
  }, [activeIndex]);*/

  return (
    <ContComoObtenerlo id='como' className={'container-fit'}>
      <StylesComoObtenerlo/>
      <ContTitle>
        <Title1>Conoce como obtener</Title1>
        <Title2>tu crédito</Title2>
      </ContTitle>
      <Grid container spacing={2} className={'grid-fit center-in-column'}>
        <Grid item md={1} className={'no-padding-left no-padding-top-mobile'}>
          &nbsp;
        </Grid>
        <Grid item xs={12} sm={12} md={4} className={'no-padding-left no-padding-top-mobile'}>
          <ContInfo>
            <TitleStep>Paso {activeIndex + 1}</TitleStep>
            <MottoStep>{info[activeIndex].title}</MottoStep>
            <DescStep>{info[activeIndex].desc}</DescStep>
            <div className="b-refs-buttons">
              <BtnCarousel className={'btn-carousel'} onClick={slidePrev}><img src={ImgArrowPrev} alt=''/></BtnCarousel>
              <BtnCarousel className={'btn-carousel'} onClick={slideNext}><img src={ImgArrowNext} alt=''/></BtnCarousel>
            </div>
          </ContInfo>
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={'no-padding-left no-padding-top-mobile'}>
          <AliceCarousel
            className={'alice-carousel'}
            mouseTracking
            // disableDotsControls
            disableButtonsControls
            items={items}
            activeIndex={activeIndex}
            responsive={responsive}
            onSlideChanged={syncActiveIndex}
          />
        </Grid>
        <Grid item md={1} className={'no-padding-left no-padding-top-mobile'}>
          &nbsp;
        </Grid>
      </Grid>
    </ContComoObtenerlo>
  );
};

export default ComoObtenerlo;
