import React from 'react';
import {
  ContBlockDesc,
  ContBlockLema,
  ContDesc,
  ContDescBold,
  ContHome,
  ContImgFirst,
  ContLema,
  ContLema1,
  ContLema2,
  ImgFirst, StylesHome,
} from './Home.styles';
import {Container, Grid} from '@mui/material';
import imgFirst from '../../assets/images/first.png';
import Button from '../Button';

const Home = () => {

  return (
    <ContHome id='home' className={'container-fit'}>
      <StylesHome/>
      <Container maxWidth={false} className={'no-padding'}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6} lg={6}>
            <ContBlockLema>
              <ContLema>
                <ContLema1>¡Compra tu equipo</ContLema1>
                <ContLema2>en pagos!</ContLema2>
              </ContLema>
              <ContBlockDesc>
                <ContDesc><ContDescBold>Plazos de hasta 24 meses</ContDescBold></ContDesc>
                <ContDesc>Pagos cómodos <ContDescBold>¡y sin tarjeta de crédito!</ContDescBold></ContDesc>
              </ContBlockDesc>
            </ContBlockLema>
          </Grid>
          <Grid item sm={12} md={0} lg={0} className={'cont-button'}>
            <Button type="/" text="Solicita tu crédito" linkTo={'https://admin.dynamicore.io/public/people/form/a071db79d3d74ea9a56c0e754a69a330'}/>
          </Grid>
          <Grid item sm={12} md={6} lg={6}>
            <ContImgFirst>
              <ImgFirst src={imgFirst} alt=''/>
            </ContImgFirst>
          </Grid>
        </Grid>
      </Container>
    </ContHome>
  );
};

export default Home;
