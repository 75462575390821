import React from 'react';
import {ContHeader, ContHeaderFirst, ContHeaderInner} from './Header.styles';
import {Logo} from '../Logo';
import Menu from '../Menu';
import Button from '../Button';
import {Grid} from '@mui/material';
import MenuMobile from '../MenuMobile';

const Header = () => {
  return (
    <ContHeaderFirst>
      <ContHeader>
        <ContHeaderInner container spacing={2}>
          <Grid item md={3}>
            <Logo/>
          </Grid>
          <Grid item xs={0} sm={0} md={6} lg={7}>
            <Menu/>
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={2}>
            <Button type="/" location='menu' text="Solicita tu crédito" linkTo={'https://admin.dynamicore.io/public/people/form/a071db79d3d74ea9a56c0e754a69a330'}/>
          </Grid>
          <Grid item xs={3} sm={3} md={0} className={'no-show-desk'}>
            <MenuMobile/>
          </Grid>
        </ContHeaderInner>
      </ContHeader>
    </ContHeaderFirst>
  );
};

export default Header;
