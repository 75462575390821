import styled, {createGlobalStyle} from 'styled-components';
import {GlobalColors, GlobalFonts} from '../../globals';

export const StylesHome = createGlobalStyle`
  .cont-button {
    display: none !important;
    @media(min-width: 300px) and (max-width: 1023px) {
      width: 100%;
      margin: 15px 0 !important;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }    
  }
`

export const ContHome = styled.div`
  width: auto;
  height: auto;
  //padding: 0 25px;
  padding: 184px 0 70px;

  @media(min-width: 300px) and (max-width: 767px){
    padding: 80px 0 50px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    padding: 100px 0 50px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    padding: 110px 0 50px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    padding: 120px 0 50px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    padding: 140px 0 50px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    padding: 140px 0 50px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    padding: 140px 0 50px;
  }
`
export const ContBlockLema = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`
export const ContLema = styled.div`
  width: auto;
  height: auto;
  margin-bottom: 15px;

  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
    text-align: center;
  }
`
export const ContLema1 = styled.div`
  font: 72px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  color: ${GlobalColors.colorText};

  @media(min-width: 300px) and (max-width: 767px){
    font: 34px ${GlobalFonts.fontBold};
    line-height: 40px;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font: 60px ${GlobalFonts.fontBold};
    line-height: 64px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font: 48px ${GlobalFonts.fontBold};
    line-height: 56px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    font: 49px ${GlobalFonts.fontBold};
    line-height: 54px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font: 54px ${GlobalFonts.fontBold};
    line-height: 60px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font: 60px ${GlobalFonts.fontBold};
    line-height: 64px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font: 62px ${GlobalFonts.fontBold};
    line-height: 68px;
  }
  @media(min-width: 1920px) and (max-width: 3200px){
    font: 72px ${GlobalFonts.fontBold};
    line-height: 80px;
  }
`
export const ContLema2 = styled.div`
  font: 72px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 80px;
  color: ${GlobalColors.colorTextSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font: 34px ${GlobalFonts.fontBold};
    line-height: 40px;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font: 60px ${GlobalFonts.fontBold};
    line-height: 64px;
  }
  @media(min-width: 1024px) and (max-width: 1079px){
    font: 48px ${GlobalFonts.fontBold};
    line-height: 56px;
  }
  @media(min-width: 1080px) and (max-width: 1219px){
    font: 49px ${GlobalFonts.fontBold};
    line-height: 54px;
  }
  @media(min-width: 1280px) and (max-width: 1407px){
    font: 54px ${GlobalFonts.fontBold};
    line-height: 60px;
  }
  @media(min-width: 1408px) and (max-width: 1439px){
    font: 60px ${GlobalFonts.fontBold};
    line-height: 64px;
  }
  @media(min-width: 1440px) and (max-width: 1919px){
    font: 62px ${GlobalFonts.fontBold};
    line-height: 68px;
  }
  @media(min-width: 1920px) and (max-width: 3200px){
    font: 72px ${GlobalFonts.fontBold};
    line-height: 80px;
  }  
`
export const ContBlockDesc = styled.div`
  width: 100%;
`
export const ContDesc = styled.div`
  font: 24px ${GlobalFonts.fontMedium};
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: ${GlobalColors.colorText};

  @media(min-width: 300px) and (max-width: 767px){
    font: 16px ${GlobalFonts.fontMedium};
    line-height: 24px;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    text-align: center;
  }
`
export const ContDescBold = styled.span`
  font: 24px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  color: ${GlobalColors.colorText};

  @media(min-width: 300px) and (max-width: 767px){
    font: 16px ${GlobalFonts.fontBold};
    line-height: 24px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    
  }
`
export const ContImgFirst = styled.div`
  width: 100%;
  height: auto;
`
export const ImgFirst = styled.img`
  width: 100%;
  height: 100%;
`
