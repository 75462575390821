import React from 'react';
import imgLogo from '../../assets/images/logo-enpagos.svg';
import imgLogoBlanco from '../../assets/images/logo-enpagos-blanco.svg';
import {ContLogo, ImgLogo} from './Logo.styles';
const Logo = ({type = 'normal', style = {}}) => {

  return (
    <ContLogo style={style}>
      <ImgLogo src={type === 'normal' ? imgLogo :  imgLogoBlanco} alt='' />
    </ContLogo>
  );
};

export default Logo;
