import React from 'react';
import {
  ContInfo,
  ContSolicita,
  ContSolicitaInner,
  ContTitle,
  Desc,
  DescBold,
  Title1,
  Title2,
  ContButton, Circle,
} from './SolicitaCredito.styles';
import Button from '../Button';

const SolicitaCredito = () => {

  return (
    <ContSolicita id='solicita'>
      <ContSolicitaInner className={'no-padding-desk container-fit_'}>
        <ContTitle>
          <Title1>¡Solicita ahora y</Title1>
          <Title2>potencia tu negocio!</Title2>
        </ContTitle>
        <ContInfo>
          <Desc>Adquiere el equipo que necesitas <DescBold>y forma parte de nuestros casos de éxito.</DescBold></Desc>
        </ContInfo>
        <ContButton>
          <Button
            type="/"
            text="Solicita tu crédito"
            linkTo={'https://admin.dynamicore.io/public/people/form/a071db79d3d74ea9a56c0e754a69a330'}
          />
        </ContButton>
      </ContSolicitaInner>
    </ContSolicita>
  );
};

export default SolicitaCredito;
