import {createGlobalStyle} from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .center{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .center-in-column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .no-padding {
    padding: 0 !important;
  }
  .no-padding-desk {
    @media(min-width: 300px) and (max-width: 1023px){
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    @media(min-width: 1024px){
      padding: 0 !important;
    }
  }
  .no-padding-left {
    padding-left: 0 !important;
  }
  .no-padding-right {
    padding-right: 0 !important;
  }
  .no-padding-top-mobile {
    @media(min-width: 300px) and (max-width: 1023px){
      padding-top: 0 !important;
    }
  }
  .grid-fit{
    width: 100% !important;
    margin-left: 0 !important;
  }
  
  .no-show-mobile {
    @media(min-width: 300px) and (max-width: 1023px){
      display: none !important;
    }
    @media(min-width: 1024px){
      display: block !important;
    }
  }
  .no-show-desk {
    @media(min-width: 1024px){
      display: none !important;
    }
  }
  
  .container-fit {
    @media(min-width: 300px) and (max-width: 1079px){
      margin: 0 20px !important;
    }
    @media(min-width: 1080px) and (max-width: 1219px){
      margin: 0 50px !important;
    }
    @media(min-width: 1280px) and (max-width: 1407px){
      margin: 0 100px !important;
    }
    @media(min-width: 1408px) and (max-width: 1439px){
      margin: 0 100px !important;
    }
    @media(min-width: 1440px) and (max-width: 1919px){
      margin: 0 100px !important;
    }
    @media(min-width: 1920px) and (max-width: 3200px){
      margin: 0 200px !important;
    }
  }
`

export default GlobalStyles;
